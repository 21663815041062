import { UserEntity } from "domain/entity/User/UserEntity";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { INITIAL_USER_COL_DEF, transferRowData } from "presentation/constant/User/UserColumnDefinition";
import { UserConStant } from "presentation/constant/User/UserConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useUserVM } from "presentation/hook/User/useUserVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useUserTracked } from "presentation/store/User/UserProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";

const UserTablePanel:React.FC = () => {
    const [ userState ] = useUserTracked();
    const [ anaInfoSate ] = useANAInfoTracked();
    const messageBarVM = useMessageBarVM();
    const userVM = useUserVM();
    const USER_CONSTANT = UserConStant.user;
    const { isTabularDataActive, lastEditRowId } = userState.userState;
    const allFormState = userState.userState.allFormState;
    const [ isShowDelete, setIsShowDelete ] = useState<boolean>(false);
    const [ deletedUser, setDeletedUser ] = useState<UserEntity>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const handleAddClick = useCallback(() => {
        userVM.onUserAddClick();
    }, [userVM])

    const handleRowDoubleClick = useCallback((user: UserEntity) => {
        userVM.onUserRowDoubleClick(user);
    }, [userVM])

    const handleRowDeleteClick = useCallback(async(user: UserEntity) => {
        setIsShowDelete(true);
        setDeletedUser(user);
    }, []);    

    const handleCancel = () => {
        setIsLoading(true);
        setIsShowDelete(false);
        userVM.loadAllUsers().then(data => {
            setIsLoading(false);
        });
    }
        
    const handleDelete = () => {
        setIsLoading(true);
        setIsShowDelete(false);
        if (!deletedUser) return;

        userVM.onUserRowDeleteClick(deletedUser).then((data) => {
            if (allFormState && allFormState["DeleteUserFail"]) {
                messageBarVM.showError(allFormState["DeleteUserFail"]?.toString());
            } else {
                messageBarVM.showSuccess(MessageConstant.common.DELETED_DATA_SUCCESSFUL)
                userVM.loadAllUsers().then(data => {
                    setIsLoading(false);
                });
            }            
        }).catch((error) => {
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        });
    }
    
    const memoUserTable = useMemo(() => {

        return <HPHTable
            id='user-table'
            columns={INITIAL_USER_COL_DEF}
            headerLabel={USER_CONSTANT.USER}
            onAddClick={handleAddClick}
            onRowDoubleClick={(e: any, user: UserEntity) => handleRowDoubleClick(user)}
            data={transferRowData(userState.users.map((user: UserEntity) => ({
                ...user,
                flag_edit: user.name === lastEditRowId,
            })))}
            showPaginator={false}
            editable={false}
            showAddIcon={anaInfoSate.allowCreate}
            showDeleteButton={anaInfoSate.allowDelete}
            showReloadIcon={false}
            onDelete={(deleteUser: UserEntity) => handleRowDeleteClick(deleteUser)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 72px)" />;
    }, [USER_CONSTANT.USER, userState.users, lastEditRowId, anaInfoSate.allowCreate, anaInfoSate.allowDelete, handleAddClick, handleRowDoubleClick, handleRowDeleteClick])

    useEffect(() => {
        userVM.onShowLoading();
        const initialScreen = async() => {   
            try {
                const results = await Promise.allSettled([
                    userVM.loadAllUsers(),
                    userVM.loadDropdownOptions()
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                      } else if (index === 1 && result.status === 'rejected') {

                      }
                })
            } catch(error) {
            }
        }
        initialScreen().then((data) => {            
            userVM.onHideLoading();
        }).catch(error => {            
            userVM.onHideLoading();
        });
    }, [userVM])

    if (userState.isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>{isTabularDataActive && <TableWrapper>{memoUserTable}</TableWrapper>}
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${UserConStant.user.USER}`} 
            contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
            visible={isShowDelete}
            onCancelClick={handleCancel}
            onDeleteClick={handleDelete}
        />
    </>;
}

export default UserTablePanel;